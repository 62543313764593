.container {
    padding: 7rem;
    display: flex;
    justify-content: center;
    column-gap: 10rem;

    .leftSide {
        h1 {
            font-size: 3rem;
            font-weight: 200;
            margin: 0;
            padding-bottom: 1rem;
        }
        p {
            margin: 0.2rem;
        }
    }
    .rightSide {
        width: 60rem;

        .filter {
            display: flex;
            column-gap: 1rem;
            position: sticky;
            button {
                border: none;
                border-radius: 10px;
                padding: 0.3rem 0.5rem;
                background-color: rgba(42, 167, 188, 0.3);

                color: var(--ColorOne);
                font-weight: 700;
                transition: 0.3s ease-in-out;

                &:hover {
                    transform: translateY(-4px);
                    background-color: rgba(42, 167, 188, 0.5);
                }
            }
        }
        .rightBelow {
            position: relative;

            section {
                width: 100%;

                h4 {
                    border-bottom: 1px solid var(--ColorOne);
                    color: var(--ColorOne);
                    padding-bottom: 0.3rem;
                    font-weight: 500;
                }

                .listContainer {
                    display: flex;
                    flex-direction: column;
                    .innerList {
                        display: flex;
                        .innerSection {
                            min-width: 10rem;
                        }
                        .list {
                            list-style-type: none;
                            font-weight: 600;
                            li {
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .container {
        display: flex;
        flex-direction: column;
        padding: 3rem 0 0 0;

        .leftSide {
            padding: 1rem;
        }
        .rightSide {
            width: 100%;
            .rightBelow {
                padding: 1rem;
                section {
                    .listContainer {
                        .innerList {
                            .innerSection {
                                min-width: 8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
