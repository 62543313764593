.wrapper {
    width: 60px;
    margin: 0 auto;

    flex-direction: column;
    display: none;

    .cheese {
        position: absolute;
        top: 27px;
        left: 10px;
        height: 6px;
        width: 40px;
        background-color: black;
        transition: background-color 100ms ease-in;

        &::before {
            content: '';
            position: absolute;
            top: -12px;
            left: 0;
            height: 6px;
            width: 40px;
            background-color: black;
            transition: all 150ms ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -12px;
            left: 0;
            height: 6px;
            width: 40px;
            background-color: black;
            transition: all 150ms ease-in-out;
        }

        .meat {
            height: 60px;
            width: 60px;
            position: absolute;
            top: -27px;
            left: -10px;
            font-family: 'Lato', sans-serif;
            font-size: 0em;
            line-height: 60px;
            text-align: center;
            text-decoration: none;
            color: black;
            cursor: pointer;
            user-select: none;
            z-index: 2;
            transition: font-size 275ms ease-out;
        }
    }

    .bun {
        position: relative;
        width: 60px;
        height: 60px;

        &:hover {
            .cheese {
                background-color: transparent;

                .meat {
                    font-size: 1rem;
                }

                &::before {
                    width: 60px;
                    left: -10px;
                    top: -22px;
                }

                &::after {
                    width: 60px;
                    left: -10px;
                    bottom: -22px;
                }
            }
        }
    }
}

.menuBox {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 1rem;
    background-color: white;
    position: absolute;
    right: 2rem;
    .linkUl {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0px;
        transition: opacity 0.2s ease 0s, transform 0.2s ease 0s;
        padding: 0px;
        -webkit-box-align: stretch;
        align-items: stretch;
        li {
            font-size: 1.6rem;
            font-weight: 400;
            margin: 1rem;
            transition: transform 0.2s ease 0s, text-shadow 0.2s ease 0s;
            letter-spacing: -0.5px;
            &:hover {
                animation: rainbow 3s infinite linear;
                transform: translateY(-2px);
            }

            a {
                padding: 0.5em 1em;
                color: inherit;
                display: block;
                text-decoration: none;
            }
            .activeLink {
                font-weight: 500;
                color: var(--ColorOne);
            }
        }
    }
}

@keyframes rainbow {
    0% {
        color: var(--FirstColor);
    }
    25% {
        color: var(--SecondColor);
    }
    50% {
        color: var(--ThirdColor);
    }
    75% {
        color: var(--FourthColor);
    }
    100% {
        color: var(--FifthColor);
    }
}
@media screen and (max-width: 30em) {
    .cheese {
        background-color: transparent;

        .meat {
            font-size: 1.25em;
        }

        &::before {
            width: 60px;
            left: -10px;
            top: -22px;
        }

        &::after {
            width: 60px;
            left: -10px;
            bottom: -22px;
        }
    }
}

@media only screen and (max-width: 940px) {
    .wrapper {
        display: flex;
    }
}
