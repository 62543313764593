.container {
    padding: 7rem;
    display: flex;
    justify-content: center;
    column-gap: 10rem;
    .leftSide {
        h1 {
            font-size: 3rem;
            font-weight: 200;
            margin: 0;
            padding-bottom: 1rem;
        }
        p {
            margin: 0.2rem;
        }
    }
    .rightSide {
        width: 60rem;
        .appView {
            .appName {
                color: var(--ColorOne);
                padding-bottom: 0.2rem;
                border-bottom: 1px solid var(--ColorOne);
                width: 100%;
            }
            .infoContainer {
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    transition: ease 0.6s;
                    .appImage {
                        width: 30rem;
                        border-radius: 8px;
                        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
                    }
                    &:hover {
                        transform: translateY(-0.2rem);
                    }
                }
            }
            .tagBox {
                padding-top: 1rem;
                display: flex;
                column-gap: 1rem;

                .tag {
                    display: flex;
                    font-size: 12px;
                    justify-content: center;
                    width: 4rem;
                    padding: 0.2rem;
                    border: 1.9px solid var(--ColorOne);
                    border-radius: 8px;
                    color: rgb(37, 147, 167);
                    background-color: rgba(42, 166, 188, 0.237);
                    cursor: default;
                }
            }
            .siteLinks {
                display: flex;
                padding-top: 1rem;
                column-gap: 3rem;
                a {
                    text-decoration: none;
                    color: black;
                    .link {
                        display: flex;
                        font-size: 12px;
                        font-weight: 600;
                        justify-content: center;
                        width: 12rem;
                        padding: 0.7rem;
                        border-radius: 5rem;
                        // background-color: rgba(108, 198, 68, 0.8);
                        background-color: rgba(64, 120, 192, 0.7);
                        border: 1psx solid rgb(64, 120, 192);
                        cursor: pointer;
                        transition: ease 0.6s;
                    }
                    &:hover {
                        transform: translateY(-0.2rem);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .container {
        display: flex;
        flex-direction: column;
        padding: 3rem 0 0 0;

        .leftSide {
            padding: 1rem;
        }
        .rightSide {
            width: 100%;
            .appView {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                .appName {
                }
                .infoContainer {
                    a {
                        .appImage {
                            width: 18rem;
                        }
                    }
                    .siteLinks {
                        column-gap: 1rem;
                        .link {
                            width: 9rem;
                        }
                    }
                }
            }
        }
    }
}
