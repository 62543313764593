//animation
@keyframes shine {
    0% {
        transform: skewY(-45deg) translateX(0);
    }
    100% {
        transform: skewY(-45deg) translateX(12.5em);
    }
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 80%;
    }
}

@keyframes blinkieLine {
    50% {
        border-color: transparent;
    }
}

.imageContainer {
    position: relative;
    height: 100vh;

    background: url('../../images/Smoke.jpg') no-repeat center center/cover;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--OverLayColor);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .contactBox {
            .weTyping {
                display: flex;
                justify-content: center;
                h1 {
                    font-size: 3rem;
                    font-weight: 200;
                    letter-spacing: 0.2rem;
                    animation: typing 1.5s steps(12, end),
                        blinkieLine 0.5s step-end infinite alternate;
                    white-space: nowrap;
                    overflow: hidden;
                    border-right: 3px solid white;
                    color: white;
                }
            }
        }

        .list {
            list-style-type: none;
            font-size: 1.3rem;
            padding: 0;
            .listItem {
                a {
                    text-decoration: none;
                    color: white;
                    display: flex;
                    align-items: center;
                    padding-bottom: 0.5rem;
                    &:hover {
                        text-decoration: underline;
                    }
                    .listIcon {
                        color: var(--ColorTwo);
                        margin-right: 1rem;
                        font-size: 2rem;
                    }
                }
            }
        }

        .buttonContainer {
            position: absolute;
            bottom: 2rem;

            .button {
                padding: 0.75em 2em;
                text-align: center;
                text-decoration: none;
                color: var(--ColorTwo);
                border: 5px solid var(--ColorTwo);
                font-size: 18px;
                color: white;
                display: inline-block;
                border-radius: 0.3em;
                transition: all 0.2s ease-in-out;
                position: relative;
                overflow: hidden;
                &:before {
                    content: '';
                    background-color: rgba(255, 255, 255, 0.5);
                    height: 100%;
                    width: 3em;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -4.5em;
                    transform: skewX(-45deg) translateX(0);
                    transition: none;
                }
                &:hover {
                    background-color: var(--ColorTwo);
                    color: #fff;
                    border-bottom: 4px solid darken(#333333, 10%);
                    &:before {
                        transform: skewX(-45deg) translateX(19.5em);
                        transition: all 0.5s ease-in-out;
                    }
                }
            }
        }
    }
}
