//overlay styling
//animations
@keyframes down1 {
    to {
        opacity: 0;
        top: 60%;
    }
}
@keyframes down2 {
    to {
        opacity: 0;
        top: 120%;
    }
}

.imageContainer {
    position: relative;
    height: 100vh;

    background: url('../../images/Smoke.jpg') no-repeat center center/cover;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--OverLayColor);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .downArrows {
            position: absolute;
            bottom: 4rem;
            height: 50px;
            width: 50px;
            display: grid;
            place-items: center;
            cursor: pointer;
            .arrow {
                display: inline-block;
                width: 50%;
                height: 50%;
                position: absolute;
                top: 0;
                border: solid #eee;
                border-width: 0 5px 5px 0;
                transform: rotate(45deg);
            }
            span:nth-of-type(2) {
                animation: down1 1.6s ease-in infinite;
            }
            span:nth-of-type(3) {
                animation: down2 1.6s ease-in infinite;
            }
        }
    }
}

.introduction {
    height: 50vh;
    background-color: var(--ColorTwo);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    scroll-margin-top: 5.5rem;
    .introBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;

        .myInfo {
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;
            .hello {
                text-align: center;
                font-weight: 300;
                font-size: 4.5rem;
                color: white;
                margin: 0;
                .myName {
                    color: var(--ColorOne);
                    font-weight: 600;
                }
            }
            p {
                font-size: 1.8rem;
                color: white;
                margin: 3px;
                span {
                    color: var(--ColorOne);
                }
            }
        }
    }
}

.cardBox {
    height: 90vh;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    background-color: #2aa7bc8c;
    .flipBox {
        // background-color: transparent;
        width: 100%;
        height: 100%;
        perspective: 1000px;
        &:hover {
            .CartWheel {
                transform: rotateX(180deg);
            }
            .flipBoxInner {
                transform: rotateY(180deg);
            }
        }
        .flipBoxInner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.9s cubic-bezier(0.175, 0.685, 0.47, 1.55) 0s;
            transform-origin: center center;
            transform-style: preserve-3d;
            position: absolute;
            inset: 0px;

            .frontside {
                position: absolute;
                width: 100%;
                height: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                background: var(--ColorOne);
                border: 1px solid rgba(255, 255, 255, 0.3);
                // backdrop-filter: blur(3px);
                border-radius: 8px;
                .hobbyContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    height: 100%;

                    .iconContainer {
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-template-rows: auto auto;
                        margin-top: 2rem;
                        .icon {
                            font-size: 4rem;
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }
                    p {
                        font-size: 1.5rem;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
                .prefContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    height: 100%;
                    .icon {
                        font-size: 5rem;
                        color: rgba(255, 255, 255, 0.6);
                    }
                    p {
                        font-size: 1.5rem;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
            .backside {
                position: absolute;
                width: 100%;
                height: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                border-radius: 8px;
                // backdrop-filter: blur(20px);
                background-color: rgba(255, 255, 255, 0.4);
                border: 1px solid rgba(255, 255, 255, 0.3);
                transform: rotateY(180deg);

                .hobbyContainer {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    height: 100%;
                    .hobbyList {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        align-items: center;

                        ul {
                            list-style-type: none;
                            text-align: left;
                            font-weight: 700;
                            li {
                                font-weight: 300;
                            }
                        }
                    }
                }
                .prefContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    height: 100%;
                    .iPrefer {
                        margin: 0;
                        padding: 0;
                    }
                    .sliderContainer {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        .label1 {
                            flex-basis: 100px;
                            align-self: flex-start;
                            align-content: center;
                            align-items: center;
                            height: 25px;
                            color: rgba(255, 255, 255, 1);
                        }
                        .range {
                            flex-basis: 200px;
                            -webkit-appearance: none;
                            width: 25%;
                            height: 5px;
                            background: rgba(255, 255, 255, 0.5);
                            outline: none;
                            border: 5px solid rgba(255, 255, 255, 1);
                            border-radius: 8px;
                            &::-webkit-slider-thumb {
                                -webkit-appearance: none;
                                appearance: none;
                                width: 30px;
                                height: 30px;
                                background: rgba(255, 255, 255, 0.5);
                                cursor: pointer;
                                border: 10px solid rgba(255, 255, 255, 1);
                                border-radius: 50%;
                            }
                            &::-moz-range-thumb {
                                width: 30px;
                                height: 30px;
                                background: rgba(255, 255, 255, 0.5);
                                cursor: pointer;
                                border: 3px solid rgba(255, 255, 255, 1);
                                border-radius: 50%;
                            }
                        }
                        .label2 {
                            flex-basis: 100px;
                            align-self: flex-end;
                            align-content: center;
                            align-items: center;
                            height: 25px;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }
        }
        .CartWheel {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.9s cubic-bezier(0.175, 0.685, 0.47, 1.55) 0s;
            transform-origin: center center;
            transform-style: preserve-3d;
            position: absolute;
            inset: 0px;

            .frontside {
                position: absolute;
                width: 100%;
                height: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                background: var(--ColorOne);
                border: 1px solid rgba(255, 255, 255, 0.3);
                // backdrop-filter: blur(3px);
                border-radius: 8px;

                .skillsContainer {
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    .icon {
                        font-size: 8rem;
                        color: rgba(255, 255, 255, 0.6);
                    }
                    p {
                        margin: 0;
                        font-size: 1.5rem;
                        color: rgba(255, 255, 255, 0.6);
                        span {
                            // font-weight: 600;
                            color: rgba(255, 255, 255, 0.9);
                        }
                    }
                }

                .eduContainer {
                    padding: 1rem;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    .icon {
                        font-size: 8rem;
                        color: rgba(255, 255, 255, 0.6);
                    }
                    p {
                        margin: 0;
                        font-size: 1.5rem;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
            .backside {
                position: absolute;
                width: 100%;
                height: 100%;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                border-radius: 8px;
                // backdrop-filter: blur(20px);
                background-color: rgba(255, 255, 255, 0.4);
                border: 1px solid rgba(255, 255, 255, 0.3);
                transform: rotateX(180deg);
                .eduContainer {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    row-gap: 2rem;
                    h4 {
                        margin: 0;
                    }
                    p {
                        margin: 0;
                    }
                    .bootCamp {
                        display: flex;
                        flex-direction: column;
                        align-content: flex-start;
                    }
                }

                .iconContainer {
                    display: grid;
                    grid-template-columns: auto auto auto;
                    align-items: center;
                    justify-items: center;
                    grid-row-gap: 0.2rem;
                    padding: 4rem 1rem 1rem 1rem;
                    svg:nth-of-type(1) {
                        width: 5rem;
                    }
                    svg:nth-of-type(2) {
                        width: 3.9rem;
                    }
                    svg:nth-of-type(3) {
                        width: 5.2rem;
                    }
                    svg:nth-of-type(4) {
                        width: 4rem;
                    }
                    svg:nth-of-type(5) {
                        width: 7rem;
                    }
                    svg:nth-of-type(6) {
                        width: 4rem;
                    }
                    svg:nth-of-type(7) {
                        width: 5rem;
                    }
                    svg:nth-of-type(8) {
                        width: 5.2rem;
                    }
                    svg:nth-of-type(9) {
                        width: 5rem;
                    }
                    svg:nth-of-type(10) {
                        width: 2.4rem;
                    }
                    svg:nth-of-type(11) {
                        width: 3rem;
                    }
                    svg:nth-of-type(12) {
                        width: 6rem;
                    }
                    svg:nth-of-type(13) {
                        width: 6rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .cardBox {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;

        .flipBox {
            .CartWheel {
                .backside {
                    .iconContainer {
                        display: grid;
                        grid-template-columns: auto auto auto;

                        grid-template-rows: auto auto auto;
                        padding: 1rem 0 0 0;
                        svg:nth-of-type(1) {
                            width: 4rem;
                        }
                        svg:nth-of-type(2) {
                            width: 2.9rem;
                        }
                        svg:nth-of-type(3) {
                            width: 4.2rem;
                        }
                        svg:nth-of-type(4) {
                            width: 3rem;
                        }
                        svg:nth-of-type(5) {
                            width: 6rem;
                        }
                        svg:nth-of-type(6) {
                            width: 2rem;
                        }
                        svg:nth-of-type(7) {
                            width: 4rem;
                        }
                        svg:nth-of-type(8) {
                            width: 4.2rem;
                        }
                        svg:nth-of-type(9) {
                            width: 4rem;
                        }
                        svg:nth-of-type(10) {
                            width: 1.7rem;
                        }
                        svg:nth-of-type(11) {
                            width: 2rem;
                        }
                        svg:nth-of-type(12) {
                            width: 4rem;
                        }
                        svg:nth-of-type(13) {
                            width: 4rem;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 770px) {
    .cardBox {
        .flipBox {
            .CartWheel {
                .backside {
                    .iconContainer {
                        svg:nth-of-type(1) {
                            width: 3.5rem;
                        }
                        svg:nth-of-type(2) {
                            width: 2.4rem;
                        }
                        svg:nth-of-type(3) {
                            width: 3.7rem;
                        }
                        svg:nth-of-type(4) {
                            width: 2.5rem;
                        }
                        svg:nth-of-type(5) {
                            width: 5.5rem;
                        }
                        svg:nth-of-type(6) {
                            width: 1.5rem;
                        }
                        svg:nth-of-type(7) {
                            width: 3.5rem;
                        }
                        svg:nth-of-type(8) {
                            width: 3.7rem;
                        }
                        svg:nth-of-type(9) {
                            width: 3.5rem;
                        }
                        svg:nth-of-type(10) {
                            width: 1.2rem;
                        }
                        svg:nth-of-type(11) {
                            width: 1.5rem;
                        }
                        svg:nth-of-type(12) {
                            width: 3.5rem;
                        }
                        svg:nth-of-type(13) {
                            width: 3.5rem;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 540px) {
    .cardBox {
        .flipBox {
            .CartWheel {
                .backside {
                    .iconContainer {
                        svg:nth-of-type(1) {
                            width: 3rem;
                        }
                        svg:nth-of-type(2) {
                            width: 1.9rem;
                        }
                        svg:nth-of-type(3) {
                            width: 3.2rem;
                        }
                        svg:nth-of-type(4) {
                            width: 2rem;
                        }
                        svg:nth-of-type(5) {
                            width: 5rem;
                        }
                        svg:nth-of-type(6) {
                            width: 1rem;
                        }
                        svg:nth-of-type(7) {
                            width: 3rem;
                        }
                        svg:nth-of-type(8) {
                            width: 3.2rem;
                        }
                        svg:nth-of-type(9) {
                            width: 3rem;
                        }
                        svg:nth-of-type(10) {
                            width: 0.7rem;
                        }
                        svg:nth-of-type(11) {
                            width: 1rem;
                        }
                        svg:nth-of-type(12) {
                            width: 3rem;
                        }
                        svg:nth-of-type(13) {
                            width: 3rem;
                        }
                    }
                }
            }
        }
    }
}
